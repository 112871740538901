import { useStaticQuery, graphql } from 'gatsby';

export const useStaticQueryGetAuctions = () => {
  const query = useStaticQuery(
    graphql`
      query subEventAuction {
        subEventAuction: allContentfulSubEventAuction(
          sort: { fields: [startDate], order: ASC }
        ) {
          nodes {
            startDate
            endDate
            id
            auction {
              name
              id
              eventAttendanceMode
              eventStatus
              webpage__auction {
                slug
                featureImage {
                  fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              location {
                address {
                  ... on ContentfulAddress {
                    id
                    addressCountry
                  }
                  ... on ContentfulAddressFreeform {
                    id
                    addressCountry
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return {
    subEventAuction: query.subEventAuction.nodes,
  };
};
